import React from "react";
import { colors } from "../../models";
import "./styles/faq.css";
export interface FaqProperties {
  index?: number;
  isCollapse: boolean;
  question: string;
  answer: string;
  toggleFaq?: any;
}

export const Faq: React.FC<FaqProperties> = ({
  answer,
  question,
  index,
  isCollapse,
  toggleFaq,
}) => {
  let isCollapseImage = isCollapse
    ? require("../../assets/img/substract.svg")
    : require("../../assets/img/plus.svg");

  return (
    <div  className="faq" key={index} onClick={() => toggleFaq(index)}>
      <div className="faq-question-container">
        <p>{question}</p>
        <img src={isCollapseImage} alt="" />
      </div>
      <div
        style={
          isCollapse
            ? {
                maxHeight: 1000,
                opacity: 1,
                padding: 20,
                transition: "all 0.3s ease-in-out",
              }
            : {
                opacity: 0,
                overflowY: "hidden",
                transition: "all 0.3s ease-in-out",
                height: 0,
              }
        }
      >
        <p style={{ color: colors.tertiary }}>{answer}</p>
      </div>
    </div>
  );
};
