import React from "react";
import { MainPage } from "./pages/main-page";
import qs from "qs";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/main.css";
import {
  CookiesStorageService,
  PersonaIdentificationService,
} from "./services";
import { AboutUsPage } from "./pages/about-us-page";
import { DownloadPage } from "./pages/download-page";
import { ComicPage } from "./pages/comic-page";
import { TermsAndConditionsPage } from "./pages/terms-and-conditions-page";

interface AppState {
  email: string;
}

class App extends React.Component<any, AppState> {
  private cookiesStorageService = new CookiesStorageService();
  private personaIdentificationService = new PersonaIdentificationService();

  state: AppState = {
    email: undefined,
  };

  componentDidMount() {
    this.loadEmail();
    this.loadReferrer();
  }

  loadEmail() {
    const email = this.cookiesStorageService.getValue("email");
    this.setState({
      email,
    });

    // if (email) {
    //   this.personaIdentificationService.identifyEmail(email);
    // }
  }

  loadReferrer() {
    if (document.referrer) {
      try {
        this.cookiesStorageService.setValue(
          "referrerPlatform",
          new URL(document.referrer).hostname,
          7
        );
      } catch {}

      this.cookiesStorageService.setValue("referrerURL", document.referrer, 7);
    }

    const pageQuery = qs.parse(window.location.search.replace("?", ""));

    if (pageQuery.r) {
      this.cookiesStorageService.setValue("referrerCode", pageQuery.r, 7);
    }
  }

  render() {
    const { email } = this.state;
    return (
      <Router>
        <Switch>
          <Route path="/terms-and-conditions">
            <TermsAndConditionsPage
              email={email}
              onEmailSetted={(email: string) => {
                this.cookiesStorageService.setValue("email", email, 1000);
                this.loadEmail();
              }}
            />
          </Route>
          {/* This is a hack so terms-and-conditions return 200 in GCS */}
          <Route path="/terms-and-conditions.html">
            <TermsAndConditionsPage
              email={email}
              onEmailSetted={(email: string) => {
                this.cookiesStorageService.setValue("email", email, 1000);
                this.loadEmail();
              }}
            />
          </Route>
          <Route path="/download">
            <DownloadPage
              email={email}
              onEmailSetted={(email: string) => {
                this.cookiesStorageService.setValue("email", email, 1000);
                this.loadEmail();
              }}
            />
          </Route>
          <Route path="/comic">
            <ComicPage
              email={email}
              onEmailSetted={(email: string) => {
                this.cookiesStorageService.setValue("email", email, 1000);
                this.loadEmail();
              }}
            />
          </Route>
          <Route path="/about-us">
            <AboutUsPage
              email={email}
              onEmailSetted={(email: string) => {
                this.cookiesStorageService.setValue("email", email, 1000);
                this.loadEmail();
              }}
            />
          </Route>
          <Route path="/">
            <MainPage
              email={email}
              onEmailSetted={(email: string) => {
                this.cookiesStorageService.setValue("email", email, 1000);
                this.loadEmail();
              }}
            />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
