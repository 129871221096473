import React from "react";
import { colors } from "../../models";

export const Testimonial: React.FC<any> = () => {
  return (
    <div
      style={{
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: colors.tertiary,
          textAlign: "center",
    
          letterSpacing: "-0.04ms",
          width:"90%"
        }}
      >
        El ser humano entiende mejor con historias y, como realmente deseamos
        que comprendas todo lo que puedes lograr <br /> si decides ser parte de
        la{" "}
        <span style={{ fontStyle: "italic", color: colors.primary }}>
          #ComunidadAgil
        </span>
        , creamos un comic.
      </p>
    </div>
  );
};
