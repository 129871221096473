import React from "react";
import { colors } from "../../models";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as Exit } from "../../assets/img/exit.svg";
import { Button } from "../button/button";
import { Element } from "./menu";
import "./menu-hamburguer.css";
interface MenuHamburguerCollapsed {
  elements: Element[];
  closeMenuHamburguer: () => void;
}

export const MenuHamburguerCollapsed: React.FC<MenuHamburguerCollapsed> = ({
  elements,
  closeMenuHamburguer,
}) => {
  return (
    <div className="menu-hamburguer-collapsed animate__animated animate__fadeIn">
      <div className="menu-hamburguer-collapsed-header">
        <Logo style={{ width: 100 }} fill={colors.primary} href="/" />
        <Exit style={{ width: 30 }} onClick={closeMenuHamburguer} />
      </div>
      <div className="menu-hamburguer-collapsed-menu">
        <div className="menu-hamburguer-collapsed-menu-container">
          {elements.map((element, elementIndex) => {
            return (
              <li key={elementIndex}>
                {element.isButton ? (
                  <Button
                    text={{ text: element.text }}
                    onClick={() => window.location.href = element.link}
                  />
                ) : (
                  <a href={element.link || "/"}>{element.text}</a>
                )}
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};
