import React from "react";
import { colors } from "../../models";
import { Button } from "../button/button";
import { Input } from "../input/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import "./footer.css";
import { EmailInputProperties } from "../../pages/download-page";

interface FooterProperties extends EmailInputProperties {
  color?: string;
  mode?: "dark" | "light";
}

export const Footer: React.FC<FooterProperties> = ({
  color = colors.black,
  email,
  mode = "dark",
  onEmailSetted,
}) => {
  const icons = [
    {
      link: "https://www.facebook.com/beagilrd",
      classIcon: "fab fa-facebook-f",
    },
    {
      link: "https://www.twitter.com/beagilrd",
      classIcon: "fab fa-twitter",
    },
    {
      link: "https://www.linkedin.com/company/beagilrd",
      classIcon: "fab fa-linkedin",
    },
    {
      link: "https://www.youtube.com/channel/UCOEHSVidNYG7ZlsShJ255aQ",
      classIcon: "fab fa-youtube",
    },
    {
      link: "https://www.instagram.com/beagilrd",
      classIcon: "fab fa-instagram",
    },
  ];

  return (
    <div className={`footer ${mode === "light" ? "footer-light" : ""}`}>
      <div className="footer-container">
        <div className="footer-left">
          <p style={{ color: mode === "light" && "white" }}>
            Subscríbete a nuestro newsletter
          </p>
          <Input
            mainClassName="main-footer-input"
            className="footer-input"
            email={email}
            onEmailSetted={onEmailSetted}
          />
          <p style={{ color: mode === "light" && "white" }}>
            Descarga la app en todas las plataformas.
          </p>
          <Button
            className="footer-button"
            text={{
              text: "Descarga ahora y úsala gratuitamente",
              color: mode === "light" ? "white" : "black",
            }}
            backgroundColor={
              mode === "light"
                ? "rgba(255, 255, 255, 0.05)"
                : "rgba(0, 0, 0, 0.05)"
            }
            hover="opacity-dark"
          />
          <div className="icons-social">
            {icons.map((icon, index) => (
              <a
                key={index}
                style={{ color: mode !== "light" ? color : "white" }}
                href={icon.link}
              >
                <i className={icon.classIcon} />
              </a>
            ))}
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-right-menu">
            <div
              className={`footer-menu ${
                mode === "light" && "footer-menu-light"
              }`}
            >
              <nav>
                <ul className={`${mode === "light" && "footer-menu-light"}`}>
                  <li style={{ fontWeight: 500 }}>Contacto</li>
                  <li className="link-menu">
                    <a href="mailto:hello@beagil.com?Subject=Hola%20BeAgil?">
                      Contáctanos
                    </a>
                  </li>
                  <li style={{ fontWeight: 500 }}>Legal</li>

                  <li className="link-menu">
                    <a href="/terms-and-conditions">Términos y Condiciones</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div
              className={`footer-menu ${
                mode === "light" && "footer-menu-light"
              }`}
            >
              <nav>
                <ul className={`${mode === "light" && "footer-menu-light"}`}>
                  <li style={{ fontWeight: 500 }}>Compañía</li>
                  <li className="link-menu">
                    <a href="/about-us">Historia</a>
                  </li>
                  <li className="link-menu">
                    <a href="/about-us/#objetive">Objetivos</a>
                  </li>
                  <li className="link-menu">
                    <a href="/#faqs">FAQs</a>
                  </li>
                  <li className="link-menu">
                    <a href="/comic">Cómic</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <p className={`${mode === "light" && "footer-menu-light"}`}>
            Hecho con <FontAwesomeIcon icon={faHeart} /> en Santo Domingo por
            BeAgil. SRL. © 2020.
          </p>
        </div>
      </div>
    </div>
  );
};
