export interface ColorsProperties {
  primary: string;
  secondary: string;
  tertiary: string;
  white: string;
  black: string;
  transparent: string;
}

export const colors: ColorsProperties = {
  primary: "#0062FF",
  secondary: "#EFEFEF",
  tertiary: "#9F9F9F",
  white: "#FFFFFF",
  black: "#000000",
  transparent: "transparent",
};
