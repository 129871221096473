import "../styles/comic-page.css";
import React from "react";
import { ReactComponent as Logo } from "../assets/img/logo.svg";

import { EmailInputProperties } from "./download-page";
import { Container } from "../components/container/container";
import { PublishedComicSection } from "../components/published-comics-section/published-comics-section";
export const ComicPage: React.FC<EmailInputProperties> = ({
  email,
  onEmailSetted,
}) => {
  document.body.style.background = "#07216E";
  return (
    <Container
      footerMode="light"
      menuStyle={{ borderBottom: "none" }}
      email={email}
      onEmailSetted={onEmailSetted}
      menuClassName="comic-page-menu"
      menuColor="#8BB9FF"
    >
      <div className="main-comic">
        <img
          className="comic-main-image"
          alt="Header Comic"
          src={require("../assets/img/header-comic.png")}
        />

        <div className="comic-main-section">
          <div className="comic-header">
            <Logo
              id="comic-main-logo"
              fill="#8BB9FF"
              className="animate__animated animate__fadeInUp"
            />
            <h1
              id="comic-main-title"
              className="animate__animated animate__fadeInUp animate__delay-1s"
            >
              COMIC
            </h1>
            <p
              id="comic-main-subtitle"
              className="animate__animated animate__fadeInUp animate__delay-1s"
            >
              “No hay necesidad de complicarse todo puede <br />
              ser simple y eficiente”
            </p>
          </div>
        </div>

        <div
          id="comics"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5vh",
              marginBottom: "20vh",
              width: "90%",
            }}
          >
            <p
              style={{
                fontSize: 32,
                textAlign: "center",
                marginBottom: "5vh",
                marginTop: "5vh",
              }}
            >
              {email && "Cómics publicados"}
            </p>
            <PublishedComicSection
              email={email}
              onEmailSetted={onEmailSetted}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
