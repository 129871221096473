import React from "react";
import { Container } from "../components/container/container";
import "../styles/about-us-page.css";
import { EmailInputProperties } from "./download-page";

export const AboutUsPage: React.FC<EmailInputProperties> = ({
  onEmailSetted,
  email,
}) => {
  const objectives = [
    {
      title: "Misión",
      description:
        "Proveer a las personas y empresas herramientas que les permitan conectarse, interactuar y desenvolverse de una manera ágil y simple ahorrando tiempo y dinero.",
    },
    {
      title: "Visión",
      description:
        "Convertirnos en la empresa número uno ofreciendo un conjunto de herramientas y servicios orientados tanto a personas como a empresas, ayudándolos a lograr sus objetivos, con la mayor simplicidad y la mejor calidad del mercado.",
    },
    {
      title: "Valores",
      description:
        "Proveer a las personas y empresas herramientas que les permitan conectarse, interactuar y desenvolverse de una manera ágil y simple ahorrando tiempo y dinero.",
    },
    {
      title: "Filosofía",
      description:
        "“No hay necesidad de complicar las cosas, todo puede ser fácil, rápido y ágil”.",
    },
  ];
  document.body.className = "background-body";
  return (
    <Container onEmailSetted={onEmailSetted} email={email}>
      <div className="about-us-page">
        <div className="about-us-container">
          <h2>¿Quiénes somos?</h2>
          <p>
            BeAgil es una empresa que nace con la finalidad de democratizar el
            acceso a tecnologías que contribuyan a mejorar la calidad de vida de
            todos sin excepción.
            <br />
            <br />
            No nos enseñan educación financiera, la aprendemos empíricamente (a
            puro dolor), a través de libros, consejos de terceros y
            profesionales. Esto debería ser una materia básica pero, mientras
            esto se formaliza en la educación de nuestros niños, hagamos la vida
            de los jóvenes y adultos un poco más ágil.
            <br />
            <br /> Desde el 2013, nuestros ingenieros han estado en el mercado,
            construyendo soluciones que han permitido ahorrar millones de
            dólares a sus clientes. Por lo que tienen la preparación y el deseo
            de ayudarte a lograr tu meta.
          </p>
        </div>
        <br/>
        <br/>
        <iframe className="about-us-video" src="https://www.youtube.com/embed/hcKOOR9HciA?autoplay=0&fs=0&iv_load_policy=3&showinfo=1&rel=0&cc_load_policy=1&start=0&end=0"
        ></iframe>
        <div
          style={{
            border: "1px solid #0062FF",
            marginTop: 100,
            marginBottom: 100,
            maxWidth: 150,
            width: "100%",
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            marginBottom: 80,
          }}
        >
          {objectives.map((objetive) => (
            <div className="objective-card" id="objetive">
              <p className="objective-title">{objetive.title}</p>
              <p className="objective-description">{objetive.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};
