import { CookiesStorageService } from "./cookies-storage-service";

export class SubscriptionService {
  cookiesStorageService = new CookiesStorageService();

  async subcribeEmail(email) {
    const response = await fetch(
      `${process.env.REACT_APP_SUBSCRIPTIONS_CREATE_API_URL}/organizers/2ffa9af8-0090-4309-a342-0711b516a220/events/611de6b5-ffa3-4d89-a953-c7d69d5a9230/subscriptions`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ email })
      }
    );

    if (response.ok) {
      return response.status === 201 ? "created" : "active";
    } else {
      throw new Error(
        "There is an issue subscribing the provided email"
      );
    }
  }
}
