import React from "react";
import { ComicSection } from "../components/comic-section/comic-section";
import { FaqsSection } from "../components/faqs-section/faqs-section";
import { FeaturesSection } from "../components/features-section/features-section";
import { Footer } from "../components/footer/footer";
import { GoalsSection } from "../components/goals-section/goals-section";
import { Header } from "../components/header-section/header";
import { InstitutionConnectionBlock } from "../components/institution-connection-block/institution-connection";
import { Testimonial } from "../components/testimonial/testimonial";
import { EmailInputProperties } from "./download-page";

export const MainPage: React.FC<EmailInputProperties> = ({
  email,
  onEmailSetted,
}) => {
  return (
    <>
      <Header />
      <GoalsSection />
      <InstitutionConnectionBlock />
      <FeaturesSection />
      <Testimonial />
      <ComicSection />
      <FaqsSection />
      <Footer email={email} onEmailSetted={onEmailSetted} />
    </>
  );
};
