import React from "react";
import { Button } from "../button/button";
import { InstitutionCard } from "./institution-card";
import "./styles/institution-connection-block.css";

export const InstitutionConnectionBlock: React.FC<any> = () => {
  const items = [
    { image: require("../../assets/img/banco-popular-logo.png") },
    { image: require("../../assets/img/claro-logo.png") },
    { image: require("../../assets/img/banco-bhd-logo.png") },
    {
      image: require("../../assets/img/banreservas-logo.png"),
    },
  ];
  return (
    <div className="institution-connection-block">
      <div className="institution-connection-container">
        <div className="institution-left">
          <h2>
            Conectate con <br /> las instituciones que usas.
          </h2>
          <Button
            hover="light"
            backgroundColor={"rgba(0, 98, 255, 0.1)"}
            text={{
              text: "Descarga ahora y úsala gratuitamente",
              color: "primary",
            }}
          />
        </div>
        <InstitutionCard items={items} />
      </div>
    </div>
  );
};
