import { CookiesStorageService } from "./cookies-storage-service";
import { GoogleTagManagerEventEmissionService } from "./gtm-event-emission-service";

export class PersonaIdentificationService {
  cookiesStorageService = new CookiesStorageService();
  gtmEventEmissionService = new GoogleTagManagerEventEmissionService()

  async identifyEmail(email) {
    const beAgilPersonaId = this.cookiesStorageService.getValue("ba_p_id");

    if (!beAgilPersonaId) {
      const personIdResponse = await fetch(
        `${process.env.REACT_APP_PERSONS_ID_GET_API_URL}/persons/id?parameterName=emails&parameterValue=${email}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json"
          }
        }
      );

      if (personIdResponse.ok) {
        const { id } = await personIdResponse.json();
        this.cookiesStorageService.setValue("ba_p_id", id, 1000);
        this.gtmEventEmissionService.emitEvent({
            event: "BeAgil Persona Found",
            email
          });
      }
    }
  }
  clearEmailIdentification() {
    this.cookiesStorageService.clearValue("ba_p_id");
  }
}
