import React, { ButtonHTMLAttributes, CSSProperties } from "react";
import { colors, ColorsProperties } from "../../models";
import "./button.css";

export interface ButtonProperties
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
  width?: string | number;
  outline?;
  onClick?;
  height?: number;
  hover?: "light" | "dark" | "opacity-dark" | "opacity-light";
  alignSelf?: string;
  className?: string;
  borderColor?: string;
  style?: CSSProperties;
  text: {
    text: string;
    color?: keyof ColorsProperties | string;
    size?: number;
  };
}

export const Button: React.FC<ButtonProperties> = ({
  backgroundColor = colors.primary,
  width,
  className,
  outline,
  height = 60,
  borderColor,
  hover = "dark",
  style,
  text,
  onClick = () => window.location.href = "/download"
}) => {
  return (
    <button
      onClick={onClick}
      className={`button hover-${hover} ${className ? className : ""}`}
      style={{
        paddingLeft: height / 2 || 15,
        paddingRight: height / 2 || 15,
        backgroundColor: !outline
          ? backgroundColor || colors.primary
          : colors.transparent,
        height,
        width,
        fontSize: text.size || "14px",
        border: !outline ? "none" : `1.7px solid ${borderColor || "#0062ff"}`,
        fontWeight: "normal",
        color: colors[text.color] || text.color || colors.white,
        ...style,
      }}
    >
      {text.text}
    </button>
  );
};
