import React from "react";
import { Container } from "../components/container/container";
import "../styles/terms-and-conditions-page.css";
import { EmailInputProperties } from "./download-page";

export const TermsAndConditionsPage: React.FC<EmailInputProperties> = ({
  onEmailSetted,
  email,
}) => {
  return (
    <Container onEmailSetted={onEmailSetted} email={email}>
      <div className="terms-and-conditions-page">
        <div className="terms-and-conditions-container">
          <h2>Términos y Condiciones</h2>
          <p>
            Estos Términos de servicio de BEAGIL (los "Términos") son un acuerdo
            entre usted y BEAGIL, SRL, ("BEAGIL") y rigen el uso de nuestros
            sitios web en www.beagil.com (el "Sitio web") y la aplicación
            (“Aplicación”) que juntos conforman los (“Mecanismos de acceso a
            servicios”) y los productos y/o servicios que están disponibles a
            través de los Mecanismos de acceso a servicios (“Servicios"). Al
            utilizar y acceder a los Servicios a través de los Mecanismos de
            acceso a servicios, usted acepta estar sujeto a estos Términos, la
            Política de privacidad y la Política de seguridad, que se incorporan
            aquí como referencia. "BEAGIL", "nosotros" o "nuestro" significa
            BEAGIL y sus sucesores, afiliados y cesionarios. "Usted" y "su"
            significa la persona que usa o accede a los Servicios.
          </p>
          <h4>1 - Los servicios</h4>
          <p>
            1.1 - General
            <br />
            <br />
            BEAGIL ofrece una serie de herramientas financieras y logísticas con
            el fin de contribuir a la agilización de los procesos y actividades
            de sus usuarios. Estos Servicios pueden ser accedidos en su variedad
            a través de los Mecanismos de acceso a servicios ya descritos.
            <br />
            <br />
            1.2 - Servicio de Finanzas
            <br />
            <br />
            Te permitirá conectarte y monitorear de manera semi-automatizada o
            totalmente automatizada las transacciones y balances de las cuentas,
            servicios o productos en la variedad de instituciones a las que
            BEAGIL se integra, brindando así, un panorama completo y en tiempo
            real de tus finanzas, facilitando la toma de decisiones y
            permitiendo así, recuperar el control de su economía al poder
            conocer su liquidez, crédito disponible y deudas de manera rápida y
            eficiente.
            <br />
            <br />
            También te permite la creación de presupuestos que refuerzan la
            capacidad de control sobre su economía al definir límites de gastos
            y poder ver el progreso de estos pudiendo notar y/o prevenir
            posibles violaciones a lo presupuestado.
          </p>
          <h4>2 - Términos de los Servicios</h4>
          <p>
            2.1 – Precio
            <br />
            <br />
            Tanto los Servicios como los Mecanismos de acceso a servicios están
            disponibles de manera gratuita por parte de BEAGIL. En su lugar, las
            empresas y organizaciones pagan a BEAGIL por prestaciones de
            servicios de tecnología, automatización y software.
            <br />
            <br />
            2.2 Conexión a Instituciones
            <br />
            <br />
            La conexión, acceso y obtención de los datos de las instituciones
            son realizados a través de integraciones creadas y mantenidas por
            BEAGIL mediante el uso de las aplicaciones, sitios web y APIs web de
            cada institución o por integraciones creadas y mantenidas por la
            misma institución a través del servicio de integración institucional
            de BEAGIL.
            <br />
            <br />
            BEAGIL no se responsabiliza por pérdidas de valores o información
            referente a las instituciones donde se conecta a través de los
            Mecanismos de acceso a servicios ya que las operaciones que BEAGIL
            realiza en dichas instituciones son de solo lectura, a la vez
            recomienda que se conecte con instituciones que contengan algún
            mecanismo de protección como autenticación de múltiples factores,
            por sus siglas en inglés (MFA) para la realización transacciones que
            representen, conlleven o estén relacionadas a movimientos
            económicos.
            <br />
            <br />
            Los usuarios que accedan a través de BEAGIL a instituciones que
            carezcan de mecanismos de autentificación de múltiples factores
            (MFA) para la realización de transacciones que representan,
            conlleven o estén relacionadas a movimientos económicos lo realizan
            por su cuenta y riesgo, siendo responsables de cualquier acción
            contraria a su buena voluntad.
            <br />
            <br />
            2.3 Conexión automática y credencial
            <br />
            <br />
            Las conexiones a instituciones al momento de ser efectuadas pueden
            opcionalmente y con consentimiento del usuario ser marcadas para ser
            actualizadas de manera automática, cuando este sea el caso, las
            credenciales como el usuario, contraseña y cualquier componente que
            se requiera para autenticar/representar al usuario ante la
            institución serán guardadas de forma segura siendo estas encriptadas
            tanto en disco como por software. Por lo que al optar por actualizar
            su conexión a la institución de manera automática usted permite a
            BEAGIL guardar estas credenciales de manera segura.
            <br />
            <br />
            De no optar por actualizar automáticamente, sus datos serán enviados
            a la institución directamente para el proceso de autenticación y
            obtención de los datos y no serán guardados.
            <br />
            <br />
            Si elimina una conexión de una institución donde ha optado por
            actualizar automáticamente, las credenciales guardadas serán
            eliminadas.
            <br />
            <br />
            2.4 Actualizaciones a los Servicios y Mecanismos de acceso a
            servicios
            <br />
            <br />
            BEAGIL podrá sin que esto suponga ninguna obligación, actualizar los
            Mecanismos de acceso a servicios sin avisar en cualquier momento,
            para corregir errores, incorporar nuevas características, realizar
            pruebas, no limitándose para otros casos. La continuidad al uso de
            los Servicios y los Mecanismos de acceso a servicios una vez
            actualizados, constituirá la aceptación por su parte de tales
            actualizaciones.
            <br />
            <br />
            2.5 Terminación de los términos
            <br />
            <br />
            BEAGIL se reserva el derecho de suspender o cancelar su acceso a los
            Servicios o de eliminar su cuenta de BEAGIL en cualquiera de estos
            casos: Incumples de forma sustancial o repetida estos términos. Si
            estamos obligados a hacerlo para cumplir un requisito legal o una
            orden judicial.
            <br />
            <br />
            Si tenemos razones suficientes para creer que su conducta (por
            ejemplo, hacking, suplantación de identidad, acoso, envío de spam)
            provoca daños o incurre en responsabilidades para un usuario, un
            tercero o BEAGIL.
            <br />
            <br />
            Usted puede poner fin en cualquier momento su relación con BEAGIL
            cancelando su cuenta de BEAGIL. El proceso de cancelación consta de
            un procedimiento que usted debe agotar con nuestro servicio al
            cliente el cual inicia con el envío de un correo electrónico
            solicitando la cancelación de su cuenta, solo al recibir una
            respuesta de confirmación usted podrá dar por terminado el contrato.
            El usuario entiende y acepta que la cancelación de su cuenta y
            acceso a los Servicios es su único derecho con respecto a cualquier
            disputa con BEAGIL.
            <br />
            <br />
            2.6 Garantías
            <br />
            <br />
            Debido a la naturaleza de los Servicios ofrecidos por nosotros,
            mediante los Mecanismos de acceso a servicios, los mismos no tienen,
            “como tal”, garantías de ningún tipo. No seremos responsables de
            cualquier daño en el que usted pueda incurrir al utilizar
            voluntariamente nuestros Servicios.
            <br />
            <br />
            En la medida máxima permitida por la ley aplicable y excepto lo
            expresamente proporcionado en estos términos, usted acepta que su
            uso de los servicios y todo el contenido (incluyendo el de terceros)
            incluyen o es accesible desde los Mecanismos de acceso a servicios
            es bajo su propio riesgo. Los Servicios y los Mecanismos de acceso a
            servicios se proporcionan "tal cual" y "según disponibilidad" sin
            ninguna garantía de ningún tipo. En la medida máxima permitida por
            la ley aplicable, nosotros y nuestros proveedores de servicios
            rechazan expresamente cualquiera y todas las condiciones,
            representaciones y garantías de cualquier tipo en cuanto al
            contenido u operación de los Mecanismos de acceso a servicios,
            Servicios y contenido (incluyendo el de terceros) incluido o
            accesible desde los Mecanismos de acceso a servicios, incluso
            expreso, implícito, estatutario u otro, incluyendo, pero sin
            limitarse a, cualquier garantía implícita de comerciabilidad,
            idoneidad para un propósito particular y no infracción.
            <br />
            <br />
            Sin limitar lo anterior, nosotros y nuestros proveedores de
            servicios no garantizan que: (a) los Servicios serán
            ininterrumpidos, oportunos, seguros o sin errores; (b) la
            información obtenida en los Mecanismos de acceso a servicios será
            exacta o confiable; (c) la calidad de los Servicios o la información
            obtenida por usted a través de los Mecanismos de acceso a servicios
            cumplirá sus expectativas o requisitos; (d) que cualquier contenido
            disponible a través de la plataforma está libre de infección por
            cualquier virus u otro código o rutinas de programación informática
            que contengan propiedades contaminantes o destructivas, o; (e)
            cualquier error en los servicios será corregido.
            <br />
            <br />
            Ninguna información oral o escrita obtenida por usted o de nuestros
            proveedores de servicios a través o de los Servicios creará
            cualquier garantía no expresamente expresada en estos términos.
            <br />
            <br />
            2.7 Responsabilidad
            <br />
            <br />
            En la medida máxima permitida por la ley aplicable, usted acepta que
            BEAGIL, sus afiliados y sus respectivos empleados, oficiales,
            directores, agentes y proveedores de servicios no serán responsables
            con usted o cualquier tercero por: (a) el desempeño de los Servicios
            o la incapacidad de utilizar los Servicios; (b) cualquier daño
            indirecto, incidental, especial, consecuente, punitivo o ejemplar,
            basado en contrato, o de otra manera, incluyendo, pero no limitado,
            daños por pérdida de beneficios, buena voluntad u otras pérdidas
            intangibles, incluso a tales personas han sido notificadas de la
            posibilidad de tales daños, que surjan o están de cualquier forma
            conectados con estos términos o los Servicios; (c) cualquier evento
            más allá de nuestro control razonable.
            <br />
            <br />
            2.8 Indemnización
            <br />
            <br />
            Usted acepta indemnizarnos, defendernos y eximirnos a nosotros y a
            cualquiera de nuestros afiliados, otorgantes de licencias y agentes,
            y a todos sus funcionarios, directores, empleados, agentes,
            proveedores de información y otorgantes de licencias indemnes de y
            contra todos y cada uno de los reclamos, daños y responsabilidades
            de terceros. Los costos (incluidos los honorarios y costos de
            abogados) incurridos por cualquiera de estas partes que surjan de o
            sean causados ​​por su acceso y uso de los Servicios, su violación
            de estos Términos, su violación de cualquier derecho de propiedad
            intelectual o violación por cualquier usuario de su cuenta de
            BEAGIL.
            <br />
            <br />
            2.9 Use of Cookies y datos de sesión
            <br />
            <br />
            Los Mecanismos de acceso a servicios requiere cookies y datos de
            sesión para funcionar correctamente. Las cookies y datos de sesión
            son pequeñas porciones de texto que residen en el dispositivo de un
            usuario y lo identifican como un usuario único. Utilizamos cookies
            para refinar nuestros Servicios y simplificar la experiencia del
            usuario, y nos permite personalizar su experiencia en los Mecanismos
            de acceso a servicios. Por estos motivos, vinculamos la información
            del usuario a nuestras cookies. Si no desea que se recopile
            información mediante el uso de cookies, existe un procedimiento
            simple en la mayoría de los navegadores que le permite negar o
            aceptar la función de cookies; sin embargo, debe tener en cuenta que
            las cookies pueden ser necesarias para proporcionarle ciertas
            funciones disponibles en los Mecanismos de acceso a servicios.
            <br />
            <br />
            2.10 Seguridad y Restricciones
            <br />
            <br />
            Cualquier intento de alterar los Mecanismos de acceso a servicios y
            / o deshabilitar o eludir sus características de seguridad;
            modificar, adaptar, traducir, descompilar o aplicar ingeniería
            inversa a cualquier parte de la Plataforma; agregar o usar
            tecnología de seguimiento de modificación o manipulación del
            comportamiento de los Mecanismos de acceso a servicios, cookies,
            balizas web u otras tecnologías de seguimiento en los Mecanismos de
            acceso a servicios; publicar o transmitir cualquier archivo que
            contenga virus, u otros elementos de naturaleza destructiva o
            contaminante; conducir el acceso no autorizado a los datos de
            usuario o cliente de BEAGIL; cargar o transmitir información falsa o
            información que infringe los derechos de propiedad intelectual;
            eliminar cualquier aviso de derechos de autor, marca registrada u
            otros derechos de propiedad contenidos en o sobre los mecanismo de
            entrega y acceso; cargar o transmitir publicidad o solicitud no
            solicitada o no autorizada o contenido nocivo, engañoso, acosador,
            difamatorio, abusivo, amenazante, obsceno, calumnioso e ilegal,
            según lo determine BEAGIL a su exclusivo criterio; hacer
            declaraciones falsas; crear cuentas de usuario por medios
            automatizados o bajo pretensiones falsas o fraudulentas; recopilar
            datos personales de los usuarios de BEAGIL sin el concentimiento del
            usuario; o violar estos Términos u otras disposiciones está sujeto a
            enjuiciamiento y / o recursos legales y otras acciones que BEAGIL
            puede, a su exclusivo criterio, elegir tomar. BEAGIL cumplirá con
            las solicitudes gubernamentales, policiales o regulatorias en toda
            la extensión de la ley.
            <br />
            <br />
            Ofrecemos los Servicios para su uso personal y empresarial para los
            fines descritos en ellos y en estos Términos. Se prohíbe cualquier
            otro uso. Solo puede usar los Servicios para su propio uso personal
            en su caso y empresarial la persona autorizada para esos fines.
            <br />
            <br />
            No puede usar los Servicios de ninguna manera que pueda perjudicar,
            sobrecargar, dañar, deshabilitar o comprometer (i) los Servicios;
            (ii) el uso y disfrute de los Servicios por parte de cualquier otra
            parte; o (iii) los servicios y productos de terceros, incluidos,
            entre otros, cualquier dispositivo a través del cual acceda a los
            Mecanismos de acceso a servicios. Usted acepta cumplir con todas las
            leyes y regulaciones locales que rigen la descarga, instalación y /
            o uso de los Mecanismos de acceso a servicios, incluidas, entre
            otras, las reglas de uso establecidas en los términos de servicio de
            la tienda de aplicaciones en línea.
            <br />
            <br />
            2.11 Tus accesos a BEAGIL
            <br />
            <br />
            Usted es el único responsable del mantenimiento, la confidencialidad
            y la seguridad de su nombre de usuario y contraseña. Salvo que la
            ley aplicable exija lo contrario, usted es responsable de todas las
            transacciones y otras actividades autorizadas o realizadas con su
            nombre de usuario y contraseña o dispositivo móvil, ya sea que usted
            esté autorizado o no. Salvo que se indique expresamente lo contrario
            en estos Términos o lo exija la ley aplicable, no somos responsables
            de las pérdidas derivadas de la del robo de su Información de
            usuario, credenciales de acceso o su dispositivo móvil o de
            transacciones no autorizadas o fraudulentas asociadas con las
            cuentas de las instituciones conectadas en BEAGIL. Sus contraseñas y
            datos confidenciales no deben compartirse por correo electrónico.
            <br />
            <br />
            Si pierde o le roban su dispositivo móvil o si sospecha que alguien
            ha obtenido acceso no autorizado a su nombre de usuario o contraseña
            o dispositivo móvil, debe comunicarse con nosotros de inmediato a
            hello@beagil.com. Para tomar cualquier medida, deberá
            proporcionarnos información para verificar su identidad.
            <br />
            <br />
            2.12 Privacidad
            <br />
            <br />
            2.12.1 Tus datos, ¿Qué información usamos?
            <br />
            <br />
            Datos personales: es cualquier información que permite identificar a
            un individuo. Las clases de datos personales que los presentes
            Mecanismos de acceso a servicios pueden recolectar incluyen el
            nombre y el apellido de la persona, domicilio, número de teléfono, y
            dirección de correo electrónico.
            <br />
            <br />
            Información técnica: Entre la información que recogemos se incluyen
            el tipo de navegador y su configuración, el tipo de dispositivo y su
            configuración, el sistema operativo, información sobre la red móvil
            (como el nombre del operador y el número de teléfono) y el número de
            versión de la aplicación. Internet Protocol (IP), credenciales,
            información del dispositivo que accedes: modelos, números de serie
            <br />
            <br />
            Comportamiento y mercadeo: datos que nos dicen cómo te comportas en
            nuestro Mecanismos de acceso a servicios, a que le das click y a que
            no, que te interesa y que no.
            <br />
            <br />
            Financieros: cuentas, balances, productos, transacciones y datos
            derivados o relacionados.
            <br />
            <br />
            Contactos: nombres, teléfonos y correos.
            <br />
            <br />
            2.12.2 Origen de los datos
            <br />
            <br />
            Proviene de los Servicios y Mecanismos de acceso a servicios.
            <br />
            <br />
            2.12.3 Destino de los dato
            <br />
            <br />
            Los datos serán almacenados en centros de datos alrededor del mundo
            como: Google Cloud, AWS, otros, y estos serán almacenados de manera
            segura, confiable, encriptados y resguardados.
            <br />
            <br />
            2.12.4 Propósito de los datos
            <br />
            <br />
            Tus datos personales serán usados para brindar una experiencia única
            en el uso de los Mecanismos de acceso a servicios, ejemplo:
            saludarte con su nombre.
            <br />
            <br />
            Tus datos técnicos serán utilizados en conjunto con tus datos
            personales para poder brindarte una experiencia de servicio de
            calidad y eficiente, poder replicar posibles errores que reportes o
            identifiquemos. Datos como por ejemplo: su mismo modelo de teléfono,
            velocidad de internet, batería etc.
            <br />
            <br />
            Tus datos de comportamiento serán utilizados para adaptar nuestra
            campañas de marketing y hacerte llegar información que entendamos
            sea relevante y de su interés como nuevas características de BEAGIL
            que te puedan interesar o algún producto financiero o logístico en
            el mercado que te pueda ser útil, así entendamos.
            <br />
            <br />
            Tus datos financiero, se utilizará para que los puedas revisar y
            monitorear en los Mecanismos de acceso a servicios, agregarlos de
            manera que puedas de forma rápida obtener valores unificados y
            concretos, construir reportes y estadísticas para que puedas tomar
            decisiones, analizar patrones de comportamiento, de compra, de gasto
            y proveer consejos financieros a partir de ellos.
            <br />
            <br />
            Tus datos de contactos, se utilizará para permitirle categorizar
            transacciones en cualquier dispositivo y plataformas donde uses la
            app, también para mostrarlos en tus reportes.
            <br />
            <br />
            2.12.4 Compartiendo tus datos
            <br />
            <br />
            BEAGIL compartirá su información personal con nuestros proveedores
            de servicios utilizados para fines de validación y eficientización
            de los procesos de comunicación de BEAGIL.
            <br />
            <br />
            BEAGIL compartirá sus datos técnicos con nuestros proveedores de
            servicios utilizados para eficientizar los servicios y soporte a su
            persona, servicios como plataforma de CRM, herramientas de registro
            logs, y cualquier otro servicio que permita a nuestros colaboradores
            identifican mejor las causas de los posibles fallos y errores para
            su posterior resolución. (No limitativos a los mencionados).
            <br />
            <br />
            BEAGIL compartirá sus datos de comportamiento con nuestros
            proveedores de servicios utilizados como herramientas de análisis
            tales como facebook pixel o google analytic las cuales nos
            permitirán agregar datos y crear reportes para la creación de
            campañas de publicidad eficientes dentro y fuera de la app.
            <br />
            <br />
            BEAGIL compartirá sus datos financieros y personales, SOLO CON SU
            CONSENTIMIENTO escrito o digital a través de los Mecanismos de
            acceso a servicios a instituciones adscritas a BEAGIL para requerir
            reportes crediticios o procesos de conozca su cliente. BEAGIL nunca
            proveerá estos datos sin su consentimiento previo.
            <br />
            <br />
            BEAGIL compartirá todos los datos técnicos,
            comportamiento, y financieros con herramienta de segmentación y
            marketing para el uso de sus campañas y comunicaciones.
            <br />
            <br />
            2.13 Propiedad intelectual
            <br />
            <br />
            BEAGIL retiene derechos de propiedad intelectual completos,
            irrevocables, libres de regalías, mundiales, perpetuos sobre
            cualquier contenido generado por el usuario cargado, compartido,
            transmitido o difundido de otro modo en los Servicios o Mecanismos
            de acceso a servicios a través de otros canales de comunicación.
            BEAGIL puede almacenar, usar, reproducir, modificar y adaptar dicho
            contenido y conservará todos los derechos sobre cualquier trabajo
            derivado o compilación de dicho contenido; los usuarios garantizan
            por sus acciones que tienen los derechos necesarios para otorgar
            tales licencias a BEAGIL.
            <br />
            <br />
            BEAGIL puede eliminar dicho contenido en cualquier momento y por
            cualquier motivo. BEAGIL es el propietario y / o usuario autorizado
            de todos los textos, imágenes, representaciones de datos, animación,
            música, sonidos y cualquier otro material de los Mecanismos de
            acceso a servicios. No puede copiar, reproducir, volver a publicar,
            difundir, explotar, distribuir ni incorporar de ninguna otra forma
            ningún material que se encuentre en los Mecanismos de acceso a
            servicios o en las comunicaciones de BEAGIL.
          </p>
          <h4>3 - Términos Generales</h4>
          <p>
            {" "}
            3.1 Ley Gobernante
            <br />
            <br />
            El material en este sitio es diseñado para ser utilizado por los
            residentes de la República Dominicana. Este acuerdo debe entonces
            ser gobernado, interpretado, y cumplido según las leyes de la
            República Dominicana, sin perjuicio de lo previsto por la sección
            anterior, en cuanto al respeto y cumplimiento de la normativa
            aplicable en atención al lugar desde donde usted acceda al sitio.
            <br />
            <br />
            3.2 Links de terceros
            <br />
            <br />
            En algunos casos, BEAGIL puede presentar links que te dirijan a
            sitios o plataformas de terceros que no son propiedad ni operados
            por la aplicación. BEAGIL no es responsable de la disponibilidad de
            estos sitios o sus contenidos. El usuario acepta que BEAGIL no es
            responsable, directa o indirectamente, de cualquier daño o pérdida
            causada o que se alegue haya sido causado por la conexión con el uso
            de cualquier contenido de cualquier sitio o servicios disponibles de
            terceras personas.
            <br />
            <br />
            3.3 Cambios a los términos y condiciones
            <br />
            <br />
            BEAGIL puede actualizar los presentes términos SIN PREVIO AVISO. Si
            modificamos de forma sustancial estos términos, te lo notificaremos
            con la debida antelación y te daremos la oportunidad de revisar los
            cambios, excepto (1) cuando lancemos un nuevo servicio o una nueva
            función; o (2) en situaciones urgentes, como en las que se buscan
            prevenir abusos continuados o responder a requisitos legales. Si no
            estás conforme con los nuevos términos, debes solicitar la
            cancelación de su cuenta y dejar de usar los servicios
            correspondientes.
            <br />
            <br />
            Usted también puedes poner fin en cualquier momento a la relación
            que mantienes con nosotros cancelando su cuenta de BEAGIL, asumiendo
            el protocolo citado en este término.
            <br />
            <br />
            3.4 Conformidad con la ley
            <br />
            <br />
            La ley otorga determinados derechos que no se pueden limitar
            mediante un contrato como los presentes términos del servicio. El
            objetivo de los presentes términos no es en ningún caso el de
            restringir estos derechos.
            <br />
            <br />
            3.5 Contacto
            <br />
            <br />
            Alguna pregunta acerca de los términos y condiciones, contactemos a{" "}
            <a href="mailto:hello@beagil.com?Subject=Hola%20BeAgil?">
              hello@beagil.com
            </a>
            <br />
            <br />
          </p>
        </div>
      </div>
    </Container>
  );
};
