import React from "react";

interface CarruselPhrasesState {
  currentVariableFraceIndex: number;
  currentVariableFraceVariationIndex: number;
}

export class VariationFraces extends React.Component<
  any,
  CarruselPhrasesState
> {
  private fraceVariations = [
    {
      variations: [
        "mejoras tu economía",
        "acabas con tus deudas de una vez por todas",
        "conoces y controlas tus gastos",
        "alcanzas tus metas financieras",
        "te olvídas de la calculadora",
        "tiramos los números por ti",
      ],
    },
  ];

  state: CarruselPhrasesState = {
    currentVariableFraceIndex: -1,
    currentVariableFraceVariationIndex: -1,
  };

  variableFraceTimeOut: any;
  variableFraceVariationsInterval: any;

  componentDidMount = () => {
    this.setupNextVariableFraceIntervals();
  };

  componentWillUnmount() {
    clearTimeout(this.variableFraceTimeOut);
    clearInterval(this.variableFraceVariationsInterval);
  }

  setupNextVariableFraceIntervals() {
    if (this.variableFraceVariationsInterval) {
      clearInterval(this.variableFraceVariationsInterval);
    }
    let variationsTimeLaps: number = 3000;
    const nextVariableFraceIndex =
      (this.state.currentVariableFraceIndex + 1) % 2;
    const nextVariableFrace = this.fraceVariations[nextVariableFraceIndex];

    this.setState({
      currentVariableFraceIndex: nextVariableFraceIndex,
      currentVariableFraceVariationIndex: 0,
    });

    this.variableFraceVariationsInterval = setInterval(() => {
      let currentVariableFraceVariationIndex: number =
        (this.state.currentVariableFraceVariationIndex + 1) %
        nextVariableFrace.variations.length;

      this.setState({
        currentVariableFraceVariationIndex,
      });
    }, variationsTimeLaps);
  }

  render() {
    return (
      <div>
        <p className="subtitle" style={{ marginBottom: 0 }}>
          Simplifica la manera en la que planificas y monitoreas tus cuentas
          mientras
        </p>
        {this.fraceVariations[0].variations.map(
          (variableFraceVariation, variationsIndex) => {
            return (
              this.state.currentVariableFraceVariationIndex ===
                variationsIndex && (
                <p
                  className="animate__animated animate__fadeInUp subtitle"
                  style={{
                    marginTop: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                    height: 30,
                  }}
                  key={variableFraceVariation}
                >
                  {variableFraceVariation}
                </p>
              )
            );
          }
        )}
      </div>
    );
  }
}
