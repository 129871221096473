import React from "react";
import { Menu } from "../menu/menu";
import { Button } from "../button/button";
import "./header.css";
import { VariationFraces } from "./variations-phrases";

export const Header: React.FC<any> = () => {
  return (
    <div className="header">
      <Menu />
      <div className="header-container">
        <h2>
          ¿Buscas organizar tus finanzas personales? <br />
          Lógralo de forma ágil.
        </h2>
        <VariationFraces />
        <Button text={{ text: "Descarga ahora y úsala gratuitamente" }} />
        <iframe className="header-video" src="https://www.youtube.com/embed/GuNVKg9b1b8?autoplay=0&fs=0&iv_load_policy=3&showinfo=1&rel=0&cc_load_policy=1&start=0&end=0"
        ></iframe>
      </div>
    </div>
  );
};
