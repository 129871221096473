import React, { CSSProperties } from "react";
import { Button } from "../button/button";
import { colors } from "../../models";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as MenuHamburguer } from "../../assets/img/menu.svg";
import { MenuHamburguerCollapsed } from "./menu-hamburger-collapsed";
import "./menu.css";

export interface Element {
  text: string;
  link: string;
  isButton?: boolean;
}

export interface MenuProperties {
  elements?: Element[];
  className?: string;
  style?: CSSProperties;
  color?: string;
}

interface MenuState {
  isCollapseMenuHamburger: boolean;
}

export class Menu extends React.Component<MenuProperties, MenuState> {
  state: MenuState = {
    isCollapseMenuHamburger: false,
  };

  showMenuHamburguer = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      isCollapseMenuHamburger: !this.state.isCollapseMenuHamburger,
    });
  };

  closeMenuHamburguer = () => {
    document.body.style.overflow = "auto";
    this.setState({
      isCollapseMenuHamburger: false,
    });
  };

  render() {
    const {
      elements = [
        { text: "¿Quiénes somos?", link: "/about-us" },
        { text: "FAQs", link: "/#faqs" },
        {
          text: "Contáctanos",
          link: "mailto:hello@beagil.com?Subject=Hola%20BeAgil?",
        },
        {
          text: "Descargar ahora",
          link: "/download",
          isButton: true,
        },
      ],
      color,
      className,
      style,
    } = this.props;

    return (
      <nav style={style}>
        <div className={`menu-container ${className}`}>
          <a href="/" style={{ display: "flex" }}>
            <Logo
              className="menu-logo"
              fill={color || colors.primary}
              href="/"
            />
          </a>
          <MenuHamburguer
            fill={color}
            className="menu-hamburguer"
            onClick={this.showMenuHamburguer}
          />

          {this.state.isCollapseMenuHamburger && (
            <MenuHamburguerCollapsed
              elements={elements}
              closeMenuHamburguer={this.closeMenuHamburguer}
            />
          )}
          <ul>
            {elements.map((element, elementIndex) => {
              return (
                <li
                  style={{ listStyle: "none", marginLeft: 35 }}
                  key={elementIndex}
                >
                  {element.isButton ? (
                    <Button
                      text={{ text: element.text }}
                      onClick={() => (window.location.href = element.link)}
                    />
                  ) : (
                    <a style={{ color }} href={element.link || "/"}>
                      {element.text}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    );
  }
}
