import React, { InputHTMLAttributes } from "react";
import { ReactComponent as Oval } from "../../assets/img/oval.svg";
import "./input.css";
import {
  GoogleTagManagerEventEmissionService,
  PersonaIdentificationService,
  SubscriptionService,
} from "../../services";
import { EmailInputProperties } from "../../pages/download-page";

export interface InputProperties
  extends InputHTMLAttributes<HTMLInputElement>,
    EmailInputProperties {
  className?: string;
  mainClassName?: string;
  buttonColor?: string;
}

interface InputState {
  email: string;
  isLoading: boolean;
  errorMessages: string[];
}

export class Input extends React.Component<InputProperties, InputState> {
  gtmEventEmissionService = new GoogleTagManagerEventEmissionService();
  personaIdentificationService = new PersonaIdentificationService();
  subscriptionService = new SubscriptionService();

  state: InputState = {
    email: "",
    isLoading: false,
    errorMessages: [],
  };

  isValidEmail(email: string) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  getEmailValidationErrors(email) {
    const errorMessages = [];

    if (!email) {
      errorMessages.push("No te olvides de escribir el correo!");
    }

    if (email && !this.isValidEmail(email)) {
      errorMessages.push(
        "Este correo no funciona, deberia ser algo como ejemplo@email.com"
      );
    }

    return errorMessages;
  }

  async signup() {
    const errorMessages = [];

    try {
      if (this.state.isLoading) {
        return;
      }

      this.setState({
        isLoading: true,
      });

      const normalizedEmail = this.state.email.toLowerCase().trim();

      errorMessages.push(...this.getEmailValidationErrors(normalizedEmail));

      if (!errorMessages.length) {
        const subscriptionStatus = await this.subscriptionService.subcribeEmail(
          normalizedEmail
        );

        // await this.personaIdentificationService.identifyEmail(normalizedEmail);

        if (this.props.onEmailSetted) {
          this.props.onEmailSetted(normalizedEmail);
        }

        // if (subscriptionStatus === "created") {
        //   this.gtmEventEmissionService.emitEvent({
        //     event: "Application Beta Subscription Created",
        //     email: normalizedEmail,
        //   });
        // } else {
        //   this.gtmEventEmissionService.emitEvent({
        //     event: "Application Beta Subscription Reaffirmed",
        //     email: normalizedEmail,
        //   });
        // }
      }
    } catch (error) {
      console.log(error);
      errorMessages.push(
        "Tuvimos problemas suscribiendo tu correo, contáctanos en las redes sociales o escríbenos a hello@beagil.com"
      );
    } finally {
      this.setState({
        isLoading: false,
        errorMessages,
      });

      // if (errorMessages.length) {
      //   this.gtmEventEmissionService.emitEvent({
      //     event: "Application Beta Subscription Failed",
      //     email: this.state.email,
      //     error: errorMessages
      //       .map((error, errorIndex) => `${errorIndex + 1}) ${error}`)
      //       .join(" "),
      //   });
      // }
    }
  }

  render() {
    const {
      onClick,
      style,
      className,
      buttonColor,
      mainClassName,
      color,
      ...props
    } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          ...style,
        }}
        className={mainClassName}
      >
        <div className={`main-input ${className}`}>
          <input
            className={`input ${
              this.state.errorMessages.length ? "input-error" : ""
            }`}
            style={{ color }}
            onChange={(e) =>
              this.setState({ email: e.target.value, errorMessages: [] })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.signup();
              }
            }}
            type="text"
            placeholder="Escribe tu correo"
            {...props}
          />
          {this.state.isLoading ? (
            <Oval
              stroke="#828282"
              className="animate__animated animate__fadeIn"
              style={{ marginRight: 20, width: 30 }}
            />
          ) : (
            <img
              alt="button-input"

              src={require("../../assets/img/arrow-right.svg")}
              className="button-input"
              onClick={() => this.signup()}
            />
          )}
        </div>
        <div className="error-list">
          <ul>
            {this.state.errorMessages.map((message, index) => (
              <li key={index} className="animate__animated animate__fadeInUp">
                <small>{message}</small>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
