import React from "react";
import "./styles/comic-section.css";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { Button } from "../button/button";
export const ComicSection: React.FC = () => {
  return (
    <div className="main-comic-section">
      <div className="comic-section">
        <img
        
          className="comic-section-image"
          src={require("../../assets/img/comic-section-image.png")}
          alt="Comic"
        />
        <Logo id="comic-section-logo" fill="#8BB9FF" />
        <h1 className="comic-section-title">COMIC</h1>
        <Button
          hover="light"
          text={{ text: "LEER COMIC", color: "#8BB9FF" }}
          borderColor="#8BB9FF"
          outline
          backgroundColor="#FFFFFF"
          onClick={() => {
            window.location.href = "/comic";
          }}
          style={{ zIndex: 100, marginTop: 40 }}
        />
      </div>
    </div>
  );
};
