import React from "react";
import { Input } from "../input/input";
import "./styles/published-comics-section.css";
export const PublishedComicSection = ({ email, onEmailSetted }) => {
  const comics = [
    {
      title: "Volumen #1",
      image: require("../../assets/img/cover-volumen-1.jpg"),
      link: "https://www.faneo.es/comics/beagil/pages/1",
    },
  ];
  if (email) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {comics.map((comic, comicIndex) => (
          <a
            key={comicIndex}
            href={comic.link}
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className="published-comic"
              style={{
                backgroundImage: `url(${comic.image})`,
              }}
            >
              <p className="published-comic-title">{comic.title}</p>
            </div>
          </a>
        ))}
      </div>
    );
  } else {
    return (
      <Input
        mainClassName="comic-page-input"
        onEmailSetted={onEmailSetted}
        email={email}
        placeholder=" Deja tu correo para ver nuestro cómic"
      />
    );
  }
};
