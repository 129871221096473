import React from "react";
import "./styles/features.css";

export interface ItemsFeatureProperties {
  title: string;
  description: string;
  image: string;
  color: string;
  isOpacity?: boolean;
  backgroundColor?: string;
}

export interface FeaturesProperties {
  items: ItemsFeatureProperties[];
}

export const Features: React.FC<FeaturesProperties> = ({ items }) => {
  return (
    <div>
      {items.map((item, itemIndex) => {
        return (
          <div
            key={itemIndex}
            className="feature"
            style={{
              backgroundColor:
                item.backgroundColor || item.color + (item.isOpacity && "1A"),
            }}
          >
            <div
              className="feature-container"
              style={
                itemIndex % 2 !== 0 ? { flexDirection: "row-reverse" } : {}
              }
            >
              <img className="feature-left" src={item.image} alt="BeAgil" />
              <div className="feature-right" style={{ color: item.color }}>
                <p className="feature-title">{item.title}</p>
                <p className="feature-description">{item.description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
