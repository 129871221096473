import React from "react";
import { Features, ItemsFeatureProperties } from "./features";
export interface FeaturesSectionProperties {}

export const FeaturesSection: React.FC<FeaturesSectionProperties> = () => {
  const items: ItemsFeatureProperties[] = [
    {
      color: "#5C9031",
      isOpacity: true,
      description:
        "Conéctate a tus  instituciones desde un solo lugar y visualiza el monto real de todos tus gastos/deudas mientras examinas tus ingresos y egresos desde lo particular (individuales) hasta lo general (montos unificados).",
      image: require("../../assets/img/features-section-1.png"),
      title: "Unifica",
    },
    {
      color: "#000000",
      backgroundColor: "#F9F9F9",
      title: "Simplifica",
      description: `Conoce tu estado financiero con solo entrar a la app en la pantalla general.
        
      Ve a simple vista lo que tienes, tu crédito disponible y el monto que debes entre todas tus cuentas y cómo estás llevando tu planificación, el progreso es real. Así mismo, podrás profundizar en cada renglón si quieres conocer los detalles.`,
      image: require("../../assets/img/features-section-2.png"),
    },
    {
      color: "#D15050",
      isOpacity: true,
      description: `¿Cuánto quieres gastar? Pon el monto que quieras en el tiempo que desees. Delimita tu presupuesto y ajústate a él. Te presentamos una barra que refleja tu progreso a medida que gastas y te acercas a cumplirlo.`,
      image: require("../../assets/img/features-section-3.png"),
      title: "Planifícate",
    },
    {
      color: "#000000",
      backgroundColor: "#F9F9F9",
      description:
        "Échale un ojo a tus números. Confirma que cada transacción sea tuya y date cuenta a tiempo cuando te alejas de tu planificación. Continúa el buen manejo y corrige a tiempo si sientes que se te va la mano.",
      image: require("../../assets/img/features-section-4.png"),
      title: "Monitorea",
    },
  ];

  return <Features items={items} />;
};
