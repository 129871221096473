import React from "react";
import { Faq, FaqProperties } from "./faq";
import "./styles/faq.css";
import { faqs } from "./faqs-date";
interface FaqsSectionState {
  faqs: FaqProperties[];
}

export class FaqsSection extends React.Component<any, FaqsSectionState> {
  state = {
    faqs,
  };

  render() {
    const { faqs } = this.state;

    const toggleFaq = (index: number) => {
      const faqs = this.state.faqs.map((faq, faqIndex) => {
        if (faqIndex === index) {
          faq.isCollapse = !faq.isCollapse;
        } else {
          faq.isCollapse = false;
        }
        return faq;
      });
      
      this.setState({ faqs });
    };

    return (
      <div id="faqs" className="faqs">
        <h2>FAQs</h2>
        {faqs.map((faq, faqIndex) => {
          return (
            <Faq
              key={faqIndex}
              index={faqIndex}
              answer={faq.answer}
              question={faq.question}
              isCollapse={faq.isCollapse}
              toggleFaq={toggleFaq}
            />
          );
        })}
      </div>
    );
  }
}
