import React from "react";

export interface ItemsGoalsProperties {
  title: string;
  description: string;
  image: any;
}

export interface GoalsProperties {
  items: ItemsGoalsProperties[];
}

export const GoalsCard: React.FC<GoalsProperties> = ({ items }) => (
  <div className="goals-card">
    {items.map((item, itemIndex) => {
      return (
        <div className="goals-card-container" key={itemIndex}>
          <img
            alt={item.title}
            src={item.image}
         
          />
          <div style={{ maxWidth: 390 }}>
            <p style={{ margin: 0, fontWeight: 500 }}>{item.title}</p>
            <p style={{ fontWeight: 300 }}>{item.description}</p>
          </div>
        </div>
      );
    })}
  </div>
);
