export class GoogleTagManagerEventEmissionService {
  emitEvent(event) {
    let normalizedEvent;
    if (typeof event === "object") {
      normalizedEvent = event;
    } else if (typeof event === "string") {
      normalizedEvent = {
        event
      };
    } else {
      throw new Error(
        `The provided event [${event}] to be submitter by [${GoogleTagManagerEventEmissionService.name}] is not valid.`
      );
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(normalizedEvent);
  }
}
