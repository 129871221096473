import React, { CSSProperties } from "react";
import { Menu, Element } from "../menu/menu";
import { Footer } from "../footer/footer";
import { EmailInputProperties } from "../../pages/download-page";

interface ContainerProperties extends EmailInputProperties {
  elements?: Element[];
  style?: CSSProperties;
  menuStyle?: CSSProperties;
  className?: string;
  menuClassName?: string;
  menuColor?: string;
  footerMode?: "light" | "dark";
}

export const Container: React.FC<ContainerProperties> = ({
  children,
  style,
  className,
  menuClassName,
  menuStyle,
  email,
  onEmailSetted,
  elements,
  menuColor,
  footerMode,
}) => {
  return (
    <div style={{width:"100%",...style}} className={className}>
      <Menu
        color={menuColor}
        elements={elements && elements}
        className={menuClassName}
        style={menuStyle}
      />
      {children}
      <Footer email={email} onEmailSetted={onEmailSetted} mode={footerMode} />
    </div>
  );
};
