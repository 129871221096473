import React from "react";
import "./styles/institution-card.css";
export interface ItemsInstitutionCard {
  image: string;
}

export interface InstitutionCardProperties {
  items: ItemsInstitutionCard[];
}

export const InstitutionCard: React.FC<InstitutionCardProperties> = ({
  items,
}) => {
  return (
    <div className="institution-card-container">
      {items.map((item, itemIndex) => {
        return (
          <div className="institution-card" key={itemIndex}>
            <img
              alt="institutions"
              src={item.image}
              style={{
                maxWidth: 100,
                maxHeight: 100,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
