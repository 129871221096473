import { FaqProperties } from "./faq";
import {} from "./faqs-section";

export const faqs: FaqProperties[] = [
  {
    question: "¿Qué es BeAgil?",
    answer:
      "Somos una empresa de tecnología dedicada a crear herramientas y soluciones que permitan a nuestros usuarios simplificar su vida.",
    isCollapse: false,
  },
  {
    question: "¿Qué ofrece?",
    answer:
      "¡Tecnología! Esa que facilita tu vida, esa que te quita o reduce actividades que roban tu tiempo, porque sí, porque podemos, porque no hay necesidad de complicar las cosas, todo puede ser fácil, rápido y ágil. Lo primero, pero no lo último que te vamos a dar; una aplicación, se llama BeAgil App y será la entrada al universo de soluciones que crearemos para agilizar tu vida.",
    isCollapse: false,
  },
  {
    question: "¿Qué puedes lograr con la App?",
    answer:
      "Es tu pase de entrada a la red ágil, con ella podrás interactuar con las soluciones y herramientas que crearemos para ti y podrás acceder a ella desde tu celular, laptop, PC, navegador (Google Chrome, Safari, Mozilla, etc...) y quién sabe, desde tu mente con Neuralink.",
    isCollapse: false,
  },
  {
    question: "¿Qué soluciones brindan?",
    answer:
      "Muchas, pero vamos por parte. Primero, tus finanzas, nuestra primera herramienta para ti es para que no sea difícil mantener, y si ya lo perdiste, recuperar el control de tus finanzas, para que te planifiques y monitorices tu dinero. Segundo, te cuento luego, suscríbete, déjanos tu correo para que te lleguen las primicias.",
    isCollapse: false,
  },
  {
    question: "¿Cuando podré usar BeAgil App?",
    answer:
      "BeAgil App está disponible para que puedas empezar a organizar tus finanzas desde su versión beta. Agregamos mejoras con frecuencia y nos encantaría que al usarla, nos des tu opinión. Al fin y al cabo, la creamos para ti, así que sientete libre de escribirnos.",
    isCollapse: false,
  },
  {
    question:
      "¿Está BeAgil asociado a las instituciones con las que se conecta?",
    answer:
      "No por el momento. No tenemos convenio ni relación con ninguna de ellas, nos apoyamos en la tecnología para poder brindarte la información de manera ágil, rápida y sencilla.",
    isCollapse: false,
  },
  {
    question: "¿En qué tiempo puedo organizar mis finanzas?",
    answer:
      "Diríamos que al instante de registrar tus cuentas y poner el monto del presupuesto que tienes planificado seguir ya comienzas a sentir como todo se ordena. Ahora bien, todo depende de tu situación financiera y de tu compromiso con ordenarlas.",
    isCollapse: false,
  },
  {
    question: "¿De verdad podré lograrlo con las herramientas que proponen?",
    answer:
      "Sí y solo si estás en disposición de darle uso. Un árbol que da sombra no se mueve a donde quiera que vayas para darte sombra, tienes que estar dentro de su cobertura. Así funciona la app, tienes que registrar tus cuentas, tener un presupuesto que quieras seguir y la disciplina de entrar con el fin de saber cómo va. Nosotros te ofrecemos las herramientas, tú debes utilizarlas.",
    isCollapse: false,
  },
  {
    question: "¿Cuánto debo pagar para usar BeAgil App?",
    answer:
      "Marc Anthony pregunta ¿Qué precio tiene el cielo? ¡BeAgil es gratis! Puedes acceder a todas las herramientas sin tener que pagar peso alguno. Utiliza todas las herramientas tecnológicas sin miedo a que, cuando sientas que la necesitas, tengas que pagar porque agotes las versión de prueba o pagar para acceder a más herramientas. Disfrútala.",
    isCollapse: false,
  },
];
