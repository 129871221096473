import React from "react";
import { Button } from "../button/button";
import { GoalsCard, ItemsGoalsProperties } from "./goals-card";
import "./goals.css";
export const GoalsSection: React.FC<any> = () => {
  const items: ItemsGoalsProperties[] = [
    {
      title: "Harás las paces con tu buró de crédito",
      description:
        "se volverá tu mejor amigo, abriéndote las puertas en caso de que decidas adquirir algún bien.",
      image: require("../../assets/img/TargetIcon.png"),
    },
    {
      title: "Experimentarás la seguridad",
      description:
        "que da estar plenamente consciente de saber en qué pie estás parado con respecto a tus finanzas.",
      image: require("../../assets/img/FinanceIcon.png"),
    },
    {
      title: "Llegarás a tener la satisfacción",
      description:
        "de entrar a tus cuentas y que tus números no te den ganas de llorar porque están más rojos que un tomate.",
      image: require("../../assets/img/AccountsIcon.png"),
    },
    {
      title: "Llénate de alegría al identificar",
      description:
        "fácilmente cada centavo que sale de tu cuenta incluidos los misteriosos cargos y las suscripciones olvidadas que dejan tu cuenta en cero.",
      image: require("../../assets/img/MoneyIcon.png"),
    },
  ];

  return (
    <div className="goals-section">
      <Button
        outline
        hover="light"
        alignSelf="center"
        text={{
          text: "Descarga ahora y úsala gratuitamente",
          color: "primary",
        }}
      />
      <h3>¿Qué puedo lograr con la app?</h3>
      <GoalsCard items={items} />
      <img className="goals-img " src={require("../../assets/img/mockup-main-header.png")} alt="header" />
    </div>

  );
};
