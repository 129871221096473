import React from "react";
import { Button } from "../components/button/button";
import { Container } from "../components/container/container";
import { Input } from "../components/input/input";
import "../styles/download-page.css";

export interface EmailInputProperties {
  email: string;
  onEmailSetted: (email: string) => any;
}

export const DownloadPage: React.FC<EmailInputProperties> = ({
  email,
  onEmailSetted,
}) => {
  const platformOptions = [
    {
      icon: "fab fa-apple",
      text: "iOS",
      isPlatform: !!navigator.platform.match(/iPhone|iphone/),
      link: "https://apps.apple.com/us/app/beagil/id1502898843",
    },
    // {
    //   icon: "fab fa-windows",
    //   text: "Windows",
    //   isPlatform: !!navigator.platform.match(/Win16|Win32|WinCE|Win/),
    // },
    {
      icon: "fab fa-android",
      text: "Android",
      isPlatform: !!navigator.appVersion.match(/android|Android/),
      link: "https://play.google.com/store/apps/details?id=com.beagil.app",
    },
    // {
    //   icon: "fab fa-linux",
    //   text: "Linux",
    //   isPlatform: !!navigator.platform.match(/Linux/),
    // },
  ];
  document.body.className = "background-body";

  const elements = [
    { text: "¿Quiénes somos?", link: "/about-us" },
    { text: "FAQs", link: "/#faqs" },
    {
      text: "Contáctanos",
      link: "mailto:hello@beagil.com?Subject=Hola%20BeAgil?",
    },
  ];
  return (
    <Container
      email={email}
      onEmailSetted={onEmailSetted}
      elements={elements}
      menuClassName="download-page-menu"
    >
      <div className="download-page">
        <h1>
          Descarga ahora <br /> y úsala gratuitamente
        </h1>

        {!email ? (
          <>
            <p>Deja tu correo aquí para descargar la aplicación.</p>
            <Input
              className="download-page-input"
              mainClassName="main-download-page-input"
              placeholder="Tu correo"
              email={email}
              onEmailSetted={onEmailSetted}
              buttonColor="#838383"
            />
          </>
        ) : (
          <div
            className="animate__animated animate__fadeIn"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {platformOptions.map((platform, index) => {
              return (
                platform.isPlatform && (
                  <div key={index} className="download-options">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <i className={platform.icon} />
                      <Button
                        text={{
                          text: `Descargar para ${platform.text}`,
                          color: "#0062ff",
                        }}
                        outline
                        hover="light"
                        onClick={() => window.open(platform.link, "_blank")}
                      />
                    </div>
                    <p>También disponible en estas plataformas</p>
                  </div>
                )
              );
            })}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {platformOptions.map((platform, index) => {
                return (
                  !platform.isPlatform && (
                    <div key={index} className="download-options secondary">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <i className={platform.icon} />
                        <Button
                          text={{
                            text: `Descargar para ${platform.text}`,
                            color: "#0062ff",
                          }}
                          outline
                          hover="light"
                          onClick={() => window.open(platform.link, "_blank")}
                        />
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};
